$backgroundColor : #F4F4F4;

.Footer {
  background-color: $backgroundColor;
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
}

.footer-container {
  display: grid;
  gap: 2.188rem;
  padding: 0rem 5rem;
  padding-top: 6.875rem;
  padding-bottom: 7.5rem;
}

.footer-text {
  margin: 0 auto;
  width: 66%;
  display: grid;
  gap: 0.938rem;
  text-align: center;
}

.footer-text-header {
  font-size: 2.25rem;
  line-height: 3.125rem;
  font-weight: 400;
}

.footer-text-body {
  font-size: 1.25rem;
  line-height: 2.125rem;
  font-weight: 400;
  opacity: 0.6;
}

.footer-button {
  margin: 0 auto;
}


@media screen and (max-width: 991px) {

  .footer-container {
    padding: 0rem;
    padding-top: 5rem;
    padding-bottom: 5.625rem;
  }

  .footer-text {
    width: 90%;
  }
}


@media screen and (max-width: 767px) {
  .Footer {
    padding: 0.938rem;
  }

  .footer-container {
    display: grid;
    gap: 2.188rem;
  }
  
  .footer-text-header {
    font-size: 1.875rem;
    line-height: 3.25rem;
  }
  
  .footer-text-body {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .footer-text {
    width: 90%;
  }
}


@media screen and (max-width: 479px) {
  .footer-text {
    width: 90%;
  }
}
.team-member-card-container {
  display: grid;
}

.team-member-card-image-container {
  margin-bottom: 2.5rem;
  border: 1px solid;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.team-member-card-image {
  width: 22.375rem;
  height: 26.25rem;
}

.team-member-card-name {
  font-size: 1.25rem;
  line-height: 2.125rem;
  margin: 0 auto;
  margin-bottom: 0.313rem;
  width: 100%;
  text-align: center;
}

.team-member-card-role {
  opacity: 0.6;
  font-size: 1rem;
  line-height: 1.75rem;
  margin: 0 auto;
  margin-bottom: 3.75rem;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 767px) {

  .team-member-card-name {
    font-size: 1rem;
    line-height: 1.75rem;
  }

}
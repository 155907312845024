.section {
  margin: 0rem 3.125rem;
}

@media screen and (max-width: 991px) {
  .section {
    margin: 0rem 1.875rem;
  }
}

@media screen and (max-width: 479px) {

  .section {
    margin: 0rem 1.25rem;
  }

}
.SectionCard {
  margin-bottom: 5rem;
}

.section-card-container {
  background-color: #fff;
  display: grid;
  gap: 0.625rem;
}

.section-card-content {
  margin: 0 auto;
}

.section-card-heading {
  opacity: 0.6;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.063rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  text-align: center;
}

.section-card-description {
  font-size: 2.25rem;
  line-height: 3.125rem;
  font-weight: 400;
  text-align: center;
}
.PageBanner {
  margin-bottom: 6.25rem;
}

.page-banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

#page-banner-video {
  object-fit: cover;
  min-width: 100%;
  height: 24.75rem;
  pointer-events: none;
  // vertical-align: middle;
}


.page-banner-header {
  font-size: 4rem;
  line-height: 5rem;
  text-transform: capitalize;
  position: absolute;
}


@media screen and (max-width: 991px) {

  .page-banner-header {
    font-size: 3.5rem;
    line-height: 4.375rem;
  }

}

@media screen and (max-width: 767px) {

  .page-banner-header {
    font-size: 3.125rem;
    line-height: 4rem;
  }

}


@media screen and (max-width: 479px) {

  .page-banner-header {
    font-size: 2.25rem;
    line-height: 3rem;
  }

}

.NavBar {
  display: grid;
}

.navbar-container {
  padding: 1.875rem 3.125rem;
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  cursor: pointer;
  transition: "opactiy 200ms ease";
  display: flex;
  align-items: center;
  width: 9.3rem;
}

.navbar-logo-img {
  width: 13.5rem;
}

.navbar-all-links-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
}

.navbar-links-collapse-menu-icon-img {
  width: 1.375rem;
}

.navbar-links-collapse-menu-icon {
  cursor: pointer;
  display: none;
}

.navbar-links-collapse-menu-container {
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  transform: translateY(0px) translateX(0px);
  transition: "transform 400ms ease 0s";
  position: absolute;
  z-index: 1000;
  display: none;
}


@media screen and (max-width: 991px) {

  .navbar-container {
    padding: 1.563rem 1.875rem;
  }
  
  .navbar-logo-img {
    width: 6.75rem;
  }

  .navbar-all-links {
    display: none;
  }
  
  .navbar-contact-us-button {
    display: none;
  }

  .navbar-links-collapse-menu-icon {
    display: flex;
    align-items: center;
  }

  .navbar-links-collapse-menu-container {
    margin-top: 5rem;
  }

}

@media screen and (max-width: 767px) {

  .navbar-container {
    padding: 1.25rem 1.875rem;
  }

  .navbar-links-collapse-menu-container {
    margin-top: 4.375rem;
  }

}


@media screen and (max-width: 479px) {

  .navbar-container {
    padding: 1.25rem 1.25rem;
  }

}
@import url("https://fonts.googleapis.com/css?family=Montserrat");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #1a1b1f;
  /*-ms-overflow-style: none;
  scrollbar-width: none;*/
  font-size: 16px;
  line-height: 28px;
}

/* body::-webkit-scrollbar {
  display: none;
} */